import * as React from "react";

export default function FilterIcon() {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20'>
			<g fill='none' fillRule='evenodd'>
				<path d='M0 0h20v20H0z' />
				<path
					stroke='#000'
					strokeLinecap='round'
					strokeWidth='1.5'
					d='M12 3a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m4 0h6M8 3H2m12 7a2 2 0 1 0 4 0 2 2 0 0 0-4 0Zm0 0H2m4 7a2 2 0 1 0-4 0 2 2 0 0 0 4 0Zm0 0h12'
				/>
			</g>
		</svg>
	);
}
