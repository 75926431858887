import * as React from "react";
import { useState, useRef } from "react";
import useOnClickOutside from "../../utils/useClickOutside";
import ChevronIcon from "./ChevronIcon";
import { Link } from "gatsby";
import { StateContext } from "../../state/global-state";

export default function DemographyDropdown({ demographics, handleCampaignDemographyClick, onClick, demography }) {
	// component states
	//const [selected, setSelected] = useState(selectData[0]);
	const [open, setOpen] = useState(false);
	const ref = useRef();

	// click outside handler
	useOnClickOutside(ref, () => setOpen(false));

	// click inside handler
	const handleClick = () => {
		setOpen(!open);
	};

	// click item handler
	const handleClose = (index) => {
		setOpen(false);
		//setSelected(selectData[index]);
	};

	// click item handler
	const selectDemography = (demography, index) => {
		handleCampaignDemographyClick(demography.id);
	};

	React.useEffect(() => {
		if (open) {
			ref.current.scrollTop = 0;
		}
	}, [open]);

	return (
		<StateContext.Consumer>
			{({ handleClear }) => {
				return (
					<div className='flex flex-wrap items-center'>
						<div className='basis-full pr-2.5'>
							<button
								tabIndex={0}
								onClick={handleClick}
								className='flex items-center justify-between w-full space-x-3 px-3 py-2 bg-white rounded-md border border-[#D6DCDE] shadow-sm transition-colors hover:bg-[#F7F7FB] active:bg-gray-200'
							>
								<div>{demography[0]?.id ? "Demographic: " + demography[0].name : "Demographics"}</div>
								<ChevronIcon />
							</button>
							<div
								ref={ref}
								className={`absolute z-[1] w-[300px] px-2 py-3 mt-2 rounded-md border border-[#D6DCDE] overflow-auto overscroll-none max-h-80 bg-white shadow-lg ${
									open === true ? "block" : "hidden"
								}`}
							>
								<ul className='flex flex-col items-center'>
									{demographics &&
										demographics.map((item, index) => (
											<li key={index} className='w-full'>
												<button
													className={`w-full text-left transition px-3 py-2 flex items-center ${
														index === 0 ? "" : "border-t"
													} ${
														demography.findIndex((obj) => obj === item) !== -1 ? "text-[#000000]" : "text-[#595959]"
													} border-[#EFEFEF] hover:text-[#000000]`}
													onClick={() => onClick(item)}
												>
													<div className={"basis-auto pr-2.5"}>
														<div
															className={`checkmark w-3.5 h-3.5 rounded border ${
																demography.findIndex((obj) => obj === item) !== -1
																	? "border-[#3D2EFF] active"
																	: "border-[#D6DCDE]"
															}`}
														/>
													</div>
													<div className={"basis-auto"}>{item.name}</div>
												</button>
											</li>
										))}
								</ul>
							</div>
						</div>
					</div>
				);
			}}
		</StateContext.Consumer>
	);
}
