import * as React from "react";
import Layout from "../components/layout";
import Detail from "../components/modules/detail";
import Login from "../components/modules/Login";
import {Helmet} from "react-helmet";
import {useEffect, useState} from "react";
import {StateContext} from "../state/global-state";


export default function CampaignDetails({location}) {
	const params = new URLSearchParams(location.search);
	const campaign = params.get("campaign");
	const video = params.get("video");

	const [isLoggedIn, setIsLoggedIn] = useState(false);

	const checkUserToken = () => {
		const accessToken = localStorage.getItem('access_token');
		if (!accessToken || accessToken === 'undefined') {
			setIsLoggedIn(false);
		}else{
			setIsLoggedIn(true);
		}
	}

	useEffect(() => {
		checkUserToken();
	}, [isLoggedIn]);

	return (
		<React.Fragment>
			{!isLoggedIn && <Login/>}

			{isLoggedIn && <StateContext.Consumer>
				{({ emotions, emotionsByDemography, demographics, handleCampaignEmotionClick, handleClear, setEmotions, setEmotionsByDemography, setDemographics, setDemographicsByEmotion }) => {
					return (
						<>
							<Helmet>
								<title>Glassview Origin - Campaigns</title>
							</Helmet>
							<Layout>
								<Detail
									demographics={demographics}
									emotions={emotions}
									emotionsByDemography={emotionsByDemography}
									setEmotions={setEmotions}
									setEmotionsByDemography={setEmotionsByDemography}
									setDemographics={setDemographics}
									setDemographicsByEmotion={setDemographicsByEmotion}
									campaignId={campaign}
									videoId={video}
								/>
							</Layout>
						</>
					)}}
				</StateContext.Consumer>
			}
		</React.Fragment>
	);
}
