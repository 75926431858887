import * as React from "react";
import { useState, useRef } from "react";
import useOnClickOutside from "../../utils/useClickOutside";
import FilterIcon from "./FilterIcon";
import ChevronIcon from "./ChevronIcon";

export default function ChartViewsDropdown() {
	// component data
	const selectData = [
		{
			id: 1,
			label: "Line Chart",
		},
		{
			id: 2,
			label: "Bar Chart",
		},
	];

	// components states
	const [selected, setSelected] = useState(selectData[0]);
	const [open, setOpen] = useState(false);
	const ref = useRef();

	// click outside handler
	useOnClickOutside(ref, () => setOpen(false));

	// click inside handler
	const handleClick = () => setOpen(true);

	// click item handler
	const handleClose = (index) => {
		setOpen(false);
		setSelected(selectData[index]);
	};

	return (
		<div className='relative'>
			<button
				className='flex hidden items-center space-x-3 px-3 py-2.5 rounded-md transition-colors bg-white hover:bg-[#F7F7FB] active:bg-gray-200 border border-[#D6DCDE] shadow-sm'
				tabIndex={0}
				onClick={handleClick}
			>
				<FilterIcon />
				<span className='leading-none'>{selected.label}</span>
				<ChevronIcon />
			</button>
			<div
				ref={ref}
				className={`absolute z-10 w-full px-2 py-2 top-12  rounded-md bg-white border border-[#D6DCDE] shadow-lg ${
					open === true ? "block" : "hidden"
				}`}
			>
				<ul className='flex flex-col items-center'>
					{selectData.map((item, index) => (
						<li key={index} className='w-full'>
							<button
								onClick={() => handleClose(index)}
								className='w-full text-left block rounded-md transition hover:text-[#3D2EFF] hover:bg-[#F4F3FF] px-3 py-1'
							>
								{item.label}
							</button>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}
