import * as React from "react";
import { useState, useRef } from "react";
import useOnClickOutside from "../../utils/useClickOutside";
import ChevronIcon from "./ChevronIcon";
import { Link } from "gatsby";
import { StateContext } from "../../state/global-state";

export default function CampaignDropdown({ campaign, campaigns }) {
	// component states
	//const [selected, setSelected] = useState(selectData[0]);
	const [open, setOpen] = useState(false);
	const ref = useRef();

	// click outside handler
	useOnClickOutside(ref, () => setOpen(false));

	// click inside handler
	const handleClick = () => {
		setOpen(true);
	};

	// click item handler
	const handleClose = (index) => {
		setOpen(false);
		//setSelected(selectData[index]);
	};

	React.useEffect(() => {
		if (open) {
			ref.current.scrollTop = 0;
		}
	}, [open]);

	return (
		<StateContext.Consumer>
			{({ handleClear, clearEmotions }) => {
				return (
					<div className='flex flex-wrap items-center lg:space-x-8'>
						<div className='basis-full lg:basis-auto'>
							<div className='max-lg:mb-2'>
								<span className='text-lg leading-none'>Campaign: </span>
							</div>
						</div>
						<div className='relative basis-full lg:basis-1/2'>
							<button
								tabIndex={0}
								onClick={handleClick}
								className='flex items-center justify-between w-full space-x-3 px-3 py-2 bg-white rounded-md border border-[#D6DCDE] shadow-sm transition-colors hover:bg-[#F7F7FB] active:bg-gray-200'
							>
								<div>{campaign && campaign.name}</div>
								<ChevronIcon />
							</button>
							<div
								ref={ref}
								className={`absolute z-[1] w-full px-2 py-3 mt-2 rounded-md border border-[#D6DCDE] overflow-auto overscroll-none max-h-80 bg-white shadow-lg ${
									open === true ? "block" : "hidden"
								}`}
							>
								<ul className='flex flex-col items-center space-y-1'>
									{campaigns &&
										campaigns.map((item, index) => (
											<li key={index} className='w-full'>
												<button
													className='w-full text-left block rounded-md transition text-[#AAA] px-3 py-1'
													disabled={true}
												>
													{item.name}
												</button>
												{item.videos_count !== 0 && (
													<ul>
														{item.videos &&
															item.videos.map((video, videoIndex) => (
																<li key={videoIndex} className='w-full'>
																	<Link to={`/details?campaign=${item.id}&video=${video.id}`} onClick={handleClose}>
																		<button
																			onClick={() => {
																				handleClear();
																				//handleClose(`/details?campaign=${item.id}&video=${video.id}`)
																			}}
																			className='w-full text-left block rounded-md transition hover:text-[#3D2EFF] hover:bg-[#F4F3FF] px-3 py-1 pl-6'
																		>
																			{video.name}
																		</button>
																	</Link>
																</li>
															))}
													</ul>
												)}
											</li>
										))}
								</ul>
							</div>
						</div>
					</div>
				);
			}}
		</StateContext.Consumer>
	);
}
