import * as React from "react";
import CampaignChartSettings from "../sections/CampaignChartSettings";
import CampaignVideoSumnary from "./CampaignVideoSummary";
import CampaignChartByDemography from "./CampaignChartByDemography";

export default function CampaignCardByDemography({ video, emotionIndex, emotion }) {
	return (
		<section className='border-b border-[#E6E6E6] md:mt-5'>
			<CampaignChartByDemography campaignVideo={video} emotion={emotion} emotionIndex={emotionIndex} />
			<CampaignChartSettings />
			{video.score && <CampaignVideoSumnary video={video} />}
		</section>
	);
}
