import * as React from "react";

export default function AccordionButton() {
	return (
		<div className='basis-auto'>
			<button className='p-3 rounded-lg transition-colors hover:bg-[#F7F7FB] active:bg-gray-200'>
				<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20'>
					<g fill='none' fillRule='evenodd'>
						<path d='M0 0h20v20H0z' />
						<path stroke='#000' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 6 8 8 8-8' />
					</g>
				</svg>
			</button>
		</div>
	);
}
