import * as React from "react";
import {
	Chart,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler,
	BarElement,
	LineController,
	BarController,
} from "chart.js";
import { Chart as ChartJS } from "react-chartjs-2"; // wrapper React components for Chart.js
import video from "../../videos/milk.mp4";
import { useState, useRef, useEffect, useContext } from "react";

import { StateContext } from "../../state/global-state";

export default function CampaignChart({ campaignVideo, chartData, details }) {
	// local states
	const [videoTimline, setVideoTimline] = useState([]);
	const [currentTime, setCurrentTime] = useState(0);
	const [isMetadataLoaded, setIsMetadataLoaded] = useState(false);
	const [currentCTX, setCurrentCTX] = useState(false);
	const [videoPlay, setVideoPlay] = useState(false);
	const [clickOnChart, setClickOnChart] = useState(false);

	// global states
	const globalStateObject = useContext(StateContext);

	const videoRef = useRef(null);
	const chartReference = useRef(null);

	// Custom tooltip build
	const getOrCreateTooltip = (chart) => {
		// grab the canvas parent node
		let tooltipDIV = chart.canvas.parentNode.querySelector("div");

		// create tooltipDIV if not exist yet
		if (!tooltipDIV) {
			tooltipDIV = document.createElement("div");
			tooltipDIV.style.width = "90px";
			tooltipDIV.style.zIndex = 1;
			tooltipDIV.style.background = "rgba(0, 0, 0, 0.7)";
			tooltipDIV.style.borderRadius = "6px";
			tooltipDIV.style.color = "white";
			tooltipDIV.style.opacity = 1;
			tooltipDIV.style.pointerEvents = "none";
			tooltipDIV.style.position = "absolute";
			tooltipDIV.style.transform = "translate(-50%, calc(-100% + -10px))";
			tooltipDIV.style.transition = "all .1s ease";
			tooltipDIV.style.position = "relative";
			tooltipDIV.style.overflow = "hidden";

			// create tooltipInnerDIV
			const tooltipInnerDIV = document.createElement("div");
			tooltipInnerDIV.setAttribute("id", "tooltipInnerDIV");
			tooltipInnerDIV.style.margin = "0px";

			// add elements to DOM
			tooltipDIV.appendChild(tooltipInnerDIV);
			chart.canvas.parentNode.appendChild(tooltipDIV);
		}

		// updates the video currentTime state that syncs the 2 video frame position
		setCurrentTime(chart.tooltip.title[0]);

		const video = videoRef.current;
		video.removeAttribute("controls");

		if (video && (video.paused || video.ended)) {
			video.currentTime = currentTime;
			video.pause();
		}

		chart.canvas.addEventListener("mouseleave", function () {
			setTimeout(function () {
				video.setAttribute("controls", true);
			}, 100);
		});

		return tooltipDIV;
	};

	// // Custom crossline build
	// const createCrossline = (chart, position = 0) => {
	// 	// grab the canvas parent node
	// 	let crosslineDIV = chart.canvas.parentNode.querySelector("s");

	// 	// create tooltipDIV if not exist yet
	// 	if (!crosslineDIV) {
	// 		crosslineDIV = document.createElement("span");
	// 		crosslineDIV.style.width = "4px";
	// 		crosslineDIV.style.zIndex = 9999;
	// 		crosslineDIV.style.background = "rgba(0, 0, 0, 1)";
	// 		crosslineDIV.style.opacity = 1;
	// 		crosslineDIV.style.pointerEvents = "none";
	// 		crosslineDIV.style.position = "absolute";
	// 		crosslineDIV.style.left = position + "px";
	// 		crosslineDIV.style.top = "10px";
	// 		crosslineDIV.style.height = chart.chartArea.height + "px";
	// 		//crosslineDIV.style.transform = `translate(10px, 0)`;
	// 		crosslineDIV.style.transition = "all .1s ease";
	// 		//crosslineDIV.style.position = "relative";
	// 		//crosslineDIV.style.overflow = "hidden";
	// 	}

	// 	chart.canvas.parentNode.appendChild(crosslineDIV);
	// 	setTimeout(function () {
	// 		crosslineDIV && chart.canvas && chart.canvas.parentNode.removeChild(crosslineDIV);
	// 	}, 250);

	// 	return crosslineDIV;
	// };

	// Handle tooltip on chart point hover
	const externalTooltipHandler = (context) => {
		if (typeof window !== "undefined") {
			// unpack canvas object
			const { chart, tooltip } = context;

			// create tooltipDIV
			const tooltipDIV = getOrCreateTooltip(chart);

			// set tooltipDIV visibility
			if (tooltip.opacity === 0) {
				tooltipDIV.style.opacity = 0;
				return;
			}

			// create tooltip content
			if (tooltip.body) {
				const bodyDATA = tooltip.dataPoints.map((i) => i);

				// create headerDIV
				const headerDIV = document.createElement("div");
				headerDIV.setAttribute("id", "headerDIV");

				// create video
				const headerVIDEO = document.createElement("video");
				headerVIDEO.setAttribute("id", "headerVIDEO");
				headerVIDEO.style.width = "100%";
				headerVIDEO.style.height = bodyDATA.length <= 3 ? "40px" : "0px";
				//headerVIDEO.src = video;
				//headerVIDEO.currentTime = currentTime;
				//headerVIDEO.load(currentTime);

				headerDIV.appendChild(headerVIDEO);

				// create bodyDIV
				const bodyDIV = document.createElement("div");
				bodyDIV.setAttribute("id", "bodyDIV");
				bodyDIV.style.display = "flex";
				bodyDIV.style.justifyContent = "space-between";
				bodyDIV.style.width = "100%";
				bodyDIV.style.position = "absolute";
				bodyDIV.style.bottom = "0px";
				bodyDIV.style.padding = "6px 16px";
				bodyDIV.style.background = "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.72) 100%)";
				bodyDIV.style.fontSize = "15px";

				// create tooltip's body content
				bodyDATA.forEach((i, index) => {
					if (index === 0 && bodyDATA.length <= 3) {
						// create left column div
						const bodyLeftCOL = document.createElement("div");
						bodyLeftCOL.setAttribute("id", "bodyContainerDIV");

						// create left text
						const bodyLeftTEXT = document.createTextNode(i.dataset.label);

						// create right column div
						const bodyRightCOL = document.createElement("div");
						bodyRightCOL.setAttribute("id", "bodyContainerDIV");

						// create right text
						const bodyRightTEXT = document.createTextNode(i.formattedValue);

						// add elements to DOM
						bodyLeftCOL.appendChild(bodyLeftTEXT);
						bodyRightCOL.appendChild(bodyRightTEXT);
						bodyDIV.appendChild(bodyLeftCOL);
						bodyDIV.appendChild(bodyRightCOL);
					}
				});

				// grab tooltipDIV
				const tooltipMERGE = document.getElementById("tooltipInnerDIV");

				// remove old children
				while (tooltipMERGE.firstChild) {
					tooltipMERGE.firstChild.remove();
				}

				// add new children to tooltipDIV
				tooltipMERGE.appendChild(headerDIV);
				tooltipMERGE.appendChild(bodyDIV);
			}

			// unpack canvas object
			const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

			// display, position and styles for tooltipDIV
			tooltipDIV.style.opacity = 1;
			tooltipDIV.style.left = positionX + tooltip.caretX + "px";
			tooltipDIV.style.top = positionY + tooltip.caretY + "px";
		}
	};

	// 2. chart elements configuration
	const options = {
		animation: {
			duration: 0,
		},
		borderWidth: 1.5,
		pointStyle: true,
		tension: 0.4,
		pointRadius: 0,
		pointHoverRadius: 4,
		pointHitRadius: 10,
		pointHoverBorderWidth: 2,

		interaction: {
			mode: "nearest",
			axis: "x",
			intersect: false,
		},

		scales: {
			y: {
				type: "linear",
				display: true,
				position: "left",
				gridLines: {
					color: "white",
				},
				title: {
					display: true,
					text: "Brain Response Correlate",
				},
				ticks: {
					// Include a dollar sign in the ticks
					font: {
						size: 11,
					},
				},
			},
			y1: {
				type: "linear",
				display: false,
				position: "right",
				grid: {
					drawOnChartArea: true,
				},
			},
			x: {
				title: {
					display: true,
					text: "seconds",
				},
				ticks: {
					// Include a dollar sign in the ticks
					font: {
						size: 11,
					},
					callback: function (value, index, ticks) {
						return Math.round(value / 10);
					},
				},
			},
		},
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: false,
				position: "top",
				labels: {
					boxWidth: 8,
					boxHeight: 8,
					pointStyle: "circle",
					usePointStyle: true,
					color: "#D9F2FF",
					padding: 25,
					font: {
						textTransform: "uppercase",
						size: 15,
						family: "Inter",
					},
				},
			},
			filler: {
				propagate: false,
			},
			"samples-filler-analyser": {
				target: "chart-analyser",
			},
			title: {
				display: false,
			},
			tooltip: {
				enabled: false,
				external: externalTooltipHandler,
				filter: (tooltipItem) => tooltipItem.datasetIndex == 1,
			},
		},
	};

	// 1. register chart elements
	Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler, BarElement, BarController, LineController);

	// 3. custom plugins

	// get the benchmark number
	let benchmarkNumber = details?.charts[0].benchmark;

	const plugins = [
		{
			id: "crossline",
			beforeDatasetsDraw(chart) {
				const {
					ctx,
					tooltip,
					chartArea: { top, bottom },
				} = chart;

				setCurrentCTX(chart);

				if (tooltip._active[0]) {
					ctx.beginPath();
					ctx.strokeStyle = "black";
					ctx.lineWidth = 1;
					ctx.moveTo(tooltip._active[0].element.x, top);
					ctx.lineTo(tooltip._active[0].element.x, bottom);
					ctx.stroke();
					ctx.restore();
				}
			},
		},
		{
			id: "horizontalLine",
			beforeDatasetsDraw(chart, args, options) {
				if (benchmarkNumber > 0) {
					const {
						ctx,
						chartArea: { left, width },
						scales: { x, y },
					} = chart;
					ctx.save();

					// draw line
					ctx.strokeStyle = "red";
					ctx.strokeWidth = 1;
					ctx.strokeRect(left, benchmarkNumber, width, 0);
					ctx.restore();

					// draw text
					ctx.fillStyle = "rgba(0, 0, 0, 0)";
					ctx.fillRect(left + 5, benchmarkNumber - 10, 70, 20);
					ctx.fillStyle = "red";
					ctx.font = "10px Arial";
					ctx.fillText("Benchmark", left + 4, benchmarkNumber - 4);
				}
			},
		},
	];
	
	// 4. provide X axis labels
	const labels = videoTimline;

	// 5. provide dataSet with presentation settings and legend names

	// var filteredArray = [];
	// filteredArray = chartData;
	//filteredArray = globalStateObject.emotions[demographyIndex].filter((item) => item.active === true).map((item) => item);

	let lineChartData = [];
	lineChartData = chartData;

	const barChartData = [
		{
			type: "bar",
			barPercentage: 1.2,
			label: "",
			data: details.intervals,
			borderColor: "rgba(203, 213, 225, 0.3)",
			backgroundColor: "rgba(203, 213, 225, 0.3)",
			yAxisID: "y1",
		},
	];

	lineChartData.forEach((element) => {
		element.type = "line";
	});

	const data = {
		labels: campaignVideo.times,
		datasets: [...barChartData, ...lineChartData],
	};

	useEffect(() => {
		const video = videoRef.current;
		video.load();

		function handleStateChange() {
			const videoLength = videoRef.current.duration;

			const seconds = [];
			for (let i = 0; i < videoLength; i++) {
				seconds.push(i);
			}

			setVideoTimline(seconds);
			setIsMetadataLoaded(true);
		}

		if (video) {
			video.addEventListener("loadedmetadata", handleStateChange);
		}

		return () => {
			if (video) {
				video.removeEventListener("loadedmetadata", handleStateChange);
			}
		};
	}, [currentCTX]);

	useEffect(() => {
		const video = videoRef.current;
		video.ontimeupdate = (event) => {
			const second = video.currentTime;
			if (currentCTX) {
				const position = Math.floor((currentCTX.chartArea.width / video.duration) * second + currentCTX.chartArea.left);
				// createCrossline(currentCTX, position);
			}
		};

		currentCTX &&
			currentCTX.canvas.addEventListener("click", function () {
				if (video.paused || video.ended) {
					video.play();
				} else {
					//video.currentTime = video.currentTime;
					video.pause();
				}
			});
	}, [currentCTX]);

	return (
		<section className='mt-[30px] px-5 lg:px-[30px]'>
			<div className='flex flex-wrap'>
				<div className={"basis-full pb-4"}>
					<h3></h3>
				</div>
				<div className='basis-full lg:basis-6/12'>
					<div className={"border border-[#E6E6E6] rounded-lg p-5"}>
						<div className='chart-pannel'>
							<div className='chart-scroll-container'>
								<div className='lg:w-full h-[280px] chart-wrapper'>
									{!isMetadataLoaded && (
										<div role='status' className='w-full h-full p-4 border border-gray-200 rounded-lg animate-pulse md:p-6'>
											<div className='flex items-end h-full space-x-6'>
												<div className='w-full bg-gray-200 rounded-t-lg h-[52%]' />
												<div className='w-full h-[50%] bg-gray-200 rounded-t-lg' />
												<div className='w-full bg-gray-200 rounded-t-lg h-[68%]' />
												<div className='w-full h-[63%] bg-gray-200 rounded-t-lg' />
												<div className='w-full bg-gray-200 rounded-t-lg h-[85%]' />
												<div className='w-full bg-gray-200 rounded-t-lg h-[98%]' />
												<div className='w-full bg-gray-200 rounded-t-lg h-[82%]' />
												<div className='w-full bg-gray-200 rounded-t-lg h-[75%]' />
												<div className='w-full bg-gray-200 rounded-t-lg h-[55%]' />
											</div>
											<span className='sr-only'>Loading...</span>
										</div>
									)}

									{isMetadataLoaded && <ChartJS type='line' id={"canvas"} options={options} data={data} plugins={plugins} />}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='mt-8 lg:mt-0 basis-full lg:basis-6/12 lg:pl-5'>
					<div className='flex items-center h-full'>
						<div className='overflow-hidden rounded-lg'>
							<video
								ref={videoRef}
								id='video-player'
								src={campaignVideo.path}
								width='600'
								height='300'
								controls='controls'
								currenttime={currentTime}
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
