import * as React from "react";
import CampaignToolbar from "../../components/sections/CampaignToolbar";
import CampaignMeta from "../../components/sections/CampaignMeta";
import CampaignTitle from "../../components/sections/CampaignTitle";
import CampaignCard from "../../components/sections/CampaignCard";
import { useEffect, useState } from "react";
import axios from "axios";
import CampaignEmotionContainer from "../sections/CampaignEmotionContainer";
import CampaignDemographyContainer from "../sections/CampaignDemographyContainer";
import CampaignCardByDemography from "../sections/CampaignCardByDemography";
import { logout } from "../functions";
import EmotionDropdown from "../elements/EmotionDropdown";
import DevicesDropdown from "../elements/DevicesDropdown";
import DemographyDropdown from "../elements/DemographyDropdown";
import DemographyBadges from "../elements/DemographyBadges";
import MetricBadges from "../elements/MetricBadges";
import DeviceBadges from "../elements/DeviceBadges";
import PublisherBadges from "../elements/PublisherBadges";
import PublisherDropdown from "../elements/PublisherDropdown";
import AccordionButton from "../elements/AccordionButton";

export default function Detail({ campaignId, videoId, emotionsByDemography }) {
	const [video, setVideo] = useState(false);
	const [campaigns, setCampaigns] = useState(false);
	const [chartData, setChartData] = useState([]);
	const [view, setView] = useState(0); // 0: metrics, 1: demographics

	const [demographics, setDemographics] = useState(null);
	const [metrics, setMetrics] = useState(null);
	const [devices, setDevices] = useState(null);
	const [publishers, setPublishers] = useState(null);
	const [responseChartData, setResponseChartData] = useState(null);

	const [metric, setMetric] = useState([]);
	const [demography, setDemography] = useState([]);
	const [device, setDevice] = useState([]);
	const [publisher, setPublisher] = useState([]);
	const [colors, setColors] = useState(null);

	const [helpBox, setHelpBox] = useState(false);
	const [destroyHelpBox, setDestroyHelpBox] = useState(false);
	const [helpView, setHelpView] = useState(1);
	const [learning, setLearning] = useState("");

	const [definitions, setDefinitions] = useState(null);

	async function getVideo() {
		setChartData([]);

		const accessToken = localStorage.getItem("access_token");

		const config = {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		};
		try {
			const response = await axios.get(`${process.env.API_URL}/campaigns/${campaignId}/videos/${videoId}`, config);

			setMetrics(response.data.data.emotions);
			setDemographics(response.data.data.demographics);
			setDevices(response.data.data.devices);
			setPublishers(response.data.data.publishers);
			setResponseChartData(response.data.data.charts);

			setVideo(response.data.data);
		} catch (error) {
			error && logout();
			console.error(error);
		}
	}

	async function getDefinitions() {
		setChartData([]);

		const accessToken = localStorage.getItem("access_token");

		const config = {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		};
		try {
			const response = await axios.get(`${process.env.API_URL}/definitions/`, config);

			setDefinitions(response.data.data);
		} catch (error) {
			error && logout();
			console.error(error);
		}
	}

	const equalsCheck = (a, b, type) => {
		//return JSON.stringify(a) === JSON.stringify(b) || a === null;
		return (a && a.some((item) => b.includes(item))) || a === null || a === 0;
	};

	async function getLearning() {
		let info = "";
		let metricArray = [];
		let demographyArray = [];
		let deviceArray = [];
		let publisherArray = [];
		let found = false;

		metric.map(function (metric) {
			metricArray.push(metric.id || 0);
		});

		demography.map(function (demography) {
			demographyArray.push(demography.id || 0);
		});

		device.map(function (device) {
			deviceArray.push(device.id || 0);
		});

		publisher.map(function (publisher) {
			publisherArray.push(publisher.id || 0);
		});

		if (video) {
			video.learnings.map(function (learning) {
				console.log(learning);
				if (
					equalsCheck(learning.emotions, metricArray, "emotions") &&
					equalsCheck(learning.demographics, demographyArray, "demographics") &&
					equalsCheck(learning.devices, deviceArray, "devices") &&
					equalsCheck(learning.publishers, publisherArray, "publishers")
				) {
					found = true;
					setLearning(learning.text);
					return true;
				} else if (!found) {
					setLearning(null);
				}
			});
			//video.learnings.findIndex(obj => obj.id === item.emotion_id) !== -1 &&
			//demography.findIndex(obj => obj.id === item.demographic_id) !== -1 &&
			//publisher.findIndex(obj => obj.id === item.publisher_id) !== -1 &&
			//device.findIndex(obj => obj.id === item.device_id) !== -1){
		}
	}

	async function getCampaign() {
		const accessToken = localStorage.getItem("access_token");

		const config = {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		};
		try {
			const response = await axios.get(`${process.env.API_URL}/campaigns/`, config);
			setCampaigns(response.data.data);
		} catch (error) {
			error && logout();
			console.error(error);
		}
	}

	async function getChartData() {
		const emotionData = [];
		let fill = {};

		responseChartData &&
			responseChartData.map(function (item, index) {
				if (
					metric.findIndex((obj) => obj.id === item.emotion_id) !== -1 &&
					//item.emotion_id === metric[0].id &&
					demography.findIndex((obj) => obj.id === item.demographic_id) !== -1 &&
					publisher.findIndex((obj) => obj.id === item.publisher_id) !== -1 &&
					//item.demographic_id === demography[0].id &&
					device.findIndex((obj) => obj.id === item.device_id) !== -1
				) {
					if (view === 0) {
						const currentMetric = metrics.filter((elem) => elem.id === item.emotion_id);
						fill = currentMetric[0].colors;
					}
					if (view === 1) {
						const currentDemography = demographics.filter((elem) => elem.id === item.demographic_id);
						fill = currentDemography[0].colors;
					}
					if (view === 2) {
						const currentDevice = devices.filter((elem) => elem.id === item.device_id);
						fill = currentDevice[0].colors;
					}
					if (view === 3) {
						const currentPublisher = publishers.filter((elem) => elem.id === item.publisher_id);
						fill = currentPublisher[0].colors;
					}

					const center_line = {
						id: item.emotion_id,
						backgroundColor: fill ? fill.border_color : "#000000",
						borderColor: fill ? fill.border_color : "#000000",
						data: item.center_line,
						label: "",
						pointHoverBackgroundColor: fill ? fill.border_color : "#000000",
					};
					const lower_line = {
						id: item.emotion_id,
						backgroundColor: "rgba(0,0,0,0)",
						borderColor: "rgba(0,0,0,0)",
						data: item.lower_line,
						label: "",
						pointHoverBackgroundColor: fill ? fill.border_color : "#000000",
					};
					const upper_line = {
						id: item.emotion_id,
						backgroundColor: fill ? fill.background_color : "#000000",
						borderColor: "rgba(0,0,0,0)",
						data: item.upper_line,
						label: "",
						pointHoverBackgroundColor: fill ? fill.background_color : "#000000",
						fill: "-1",
					};

					emotionData.push(center_line);
					emotionData.push(lower_line);
					emotionData.push(upper_line);
				}
			});
		await setChartData(emotionData);
	}

	function clearFilter() {
		setMetric([metrics[0]]);
		setDemography([demographics[0]]);
		setDevice([devices[0]]);
		setPublisher([publishers[0]]);

		getChartData();
	}

	function handleMetricClick(item, isBadge = false) {
		if (isBadge) {
			addObjectOrRemoveIfExists(metric, item);
			setColors(item.colors);
		} else {
			setMetric([item]);
		}
		getChartData();
	}
	function handleDemographyClick(item, isBadge = false) {
		if (isBadge) {
			addObjectOrRemoveIfExists(demography, item);
			setColors(item.colors);
		} else {
			setDemography([item]);
		}
		getChartData();
	}
	function handleDeviceClick(item, isBadge = false) {
		if (isBadge) {
			addObjectOrRemoveIfExists(device, item);
			setColors(item.colors);
		} else {
			setDevice([item]);
		}
		getChartData();
	}

	function handlePublisherClick(item, isBadge = false) {
		if (isBadge) {
			addObjectOrRemoveIfExists(publisher, item);
			setColors(item.colors);
		} else {
			setPublisher([item]);
		}
		getChartData();
	}

	function addObjectOrRemoveIfExists(array, objectToAddOrRemove) {
		const index = array.findIndex((obj) => obj === objectToAddOrRemove);

		if (index !== -1) {
			// Az objektum már létezik a tömbben, töröljük.
			array.splice(index, 1);
		} else {
			// Az objektum nincs a tömbben, hozzáadjuk.
			array.push(objectToAddOrRemove);
		}
	}

	useEffect(() => {
		getVideo();
		getCampaign();
		getChartData();
		getDefinitions();
	}, [campaignId, videoId]);

	useEffect(() => {
		getChartData();
	}, [metric, demography, device, publisher]);

	useEffect(() => {
		getLearning();
	}, [colors, metric, demography, device, publisher]);

	useEffect(() => {
		if (metrics && metrics.length > 0) {
			setMetric([metrics[0]]);
			setColors(metrics[0].colors);
		}
		if (demographics && demographics.length > 0) {
			setDemography([demographics[0]]);
		}
		if (devices && devices.length > 0) {
			setDevice([devices[0]]);
		}
		if (publishers && publishers.length > 0) {
			setPublisher([publishers[0]]);
		}
	}, [metrics, demographics, devices, publishers]);

	useEffect(() => {
		view === 0 && metrics && setColors(metrics[0].colors);
		view === 1 && demographics && setColors(demographics[0].colors);
		view === 2 && devices && setColors(devices[0].colors);
		view === 3 && publishers && setColors(publishers[0].colors);

		metrics && setMetric([metrics[0]]);
		demographics && setDemography([demographics[0]]);
		devices && setDevice([devices[0]]);
		publishers && setPublisher([publishers[0]]);
	}, [view]);

	// console.log("Video: ", video);

	return (
		<main className='flex-grow'>
			<CampaignToolbar campaign={video} campaigns={campaigns} />
			<section id='body' className={"gradient-bg pb-20 h-full"}>
				<div className='container h-full mx-auto'>
					<div className='flex flex-wrap h-full'>
						{video && (
							<div className={"basis-full pt-10 pb-10"}>
								<CampaignTitle video={video} />
								<CampaignMeta video={video} />
							</div>
						)}
						<div className={"basis-full rounded-lg bg-white"}>
							<div className={"flex border-b border-[#D6DCDE] mx-5 lg:mx-[30px] mt-[15px] justify-between items-center"}>
								<div className={"basis-auto"}>
									<div className={"flex view-selector"}>
										<div className={"basis-auto pr-4 md:pr-10 mt-[1px]"}>
											<div
												onClick={() => setView(0)}
												className={`md:text-[22px] text-[#000000] cursor-pointer py-3.5 ${view === 0 ? "active" : ""}`}
											>
												Metrics
											</div>
										</div>
										<div className={"basis-auto pr-4 md:pr-10 mt-[1px]"}>
											<div
												onClick={() => setView(1)}
												className={`md:text-[22px] text-[#000000] cursor-pointer py-3.5 ${view === 1 ? "active" : ""}`}
											>
												Demographics
											</div>
										</div>
										<div className={"basis-auto pr-4 md:pr-10 md:mt-[1px]"}>
											<div
												onClick={() => setView(2)}
												className={`md:text-[22px] text-[#000000] cursor-pointer py-3.5 ${view === 2 ? "active" : ""}`}
											>
												Devices
											</div>
										</div>
										<div className={"basis-auto pr-4 md:mt-[1px]"}>
											<div
												onClick={() => setView(3)}
												className={`md:text-[22px] text-[#000000] cursor-pointer py-3.5 ${view === 3 ? "active" : ""}`}
											>
												Publishers
											</div>
										</div>
									</div>
								</div>
								<div className={"basis-auto relative"}>
									<button className={"btn"} onClick={() => setHelpBox(true)}>
										<div className={"flex items-center space-x-1.5"}>
											<div className={"basis-auto pr-1"}>
												<svg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg'>
													<g id='DASHBOARD' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
														<g
															id='DETAIL-infos'
															transform='translate(-1431.000000, -395.000000)'
															stroke='#000000'
															strokeWidth='1.5'
														>
															<g id='Group-2-Copy-2' transform='translate(1432.000000, 384.000000)'>
																<g id='Info_duotone_line' transform='translate(0.000000, 12.000000)'>
																	<circle
																		id='Oval'
																		fillRule='nonzero'
																		transform='translate(9.000000, 9.000000) scale(-1, 1) translate(-9.000000, -9.000000) '
																		cx='9'
																		cy='9'
																		r='9'
																	/>
																	<line x1='9' y1='9' x2='9' y2='14.4' id='Path' strokeLinecap='round' />
																	<line x1='9' y1='5.5' x2='9' y2='4.6' id='Path' strokeLinecap='round' />
																</g>
															</g>
														</g>
													</g>
												</svg>
											</div>
											<div className={"basis-auto hidden leading-none md:block"}>Help</div>
										</div>
									</button>

									{helpBox && !destroyHelpBox && (
										<div className={"p-6 bg-[#EDEFF0] absolute z-[10] w-[300px] md:w-[400px] right-0 mt-3 text-left help-box"}>
											<div className={"flex border-b border-[#D6DCDE] mb-6 items-center justify-between"}>
												<div className={"basis-auto"}>
													<div className={"flex"}>
														{definitions.map(function (definition, index) {
															return (
																<div className={"basis-auto pr-4"} key={index}>
																	<div
																		onClick={() => setHelpView(definition.id)}
																		className={`text-[16px] text-[#000000] cursor-pointer pb-2.5 helpSwitch ${
																			helpView === definition.id ? "active" : ""
																		}`}
																	>
																		{definition.title}
																	</div>
																</div>
															);
														})}
													</div>
												</div>
												<div className={"basis-auto pb-2.5"}>
													<div className={"cursor-pointer"} onClick={() => setHelpBox(false)}>
														<svg
															width='10px'
															height='10px'
															viewBox='0 0 10 10'
															version='1.1'
															xmlns='http://www.w3.org/2000/svg'
														>
															<g
																stroke='none'
																strokeWidth='1'
																fill='none'
																fillRule='evenodd'
																strokeLinecap='round'
																strokeLinejoin='round'
															>
																<g
																	transform='translate(-1467.000000, -470.000000)'
																	stroke='#000000'
																	strokeWidth='1.5'
																>
																	<g transform='translate(1083.000000, 432.514719)'>
																		<g transform='translate(385.000000, 38.485281)'>
																			<line x1='0' y1='0' x2='8' y2='8' id='Path' />
																			<line
																				x1='0'
																				y1='0'
																				x2='8'
																				y2='8'
																				id='Path'
																				transform='translate(4.000000, 4.000000) scale(-1, 1) translate(-4.000000, -4.000000) '
																			/>
																		</g>
																	</g>
																</g>
															</g>
														</svg>
													</div>
												</div>
											</div>

											<div className={"flex help-text"}>
												{definitions.map(function (definition, index) {
													return (
														definition &&
														definition.id === helpView && (
															<div
																key={index}
																className={"basis-full"}
																dangerouslySetInnerHTML={{ __html: definition.content }}
															/>
														)
													);
												})}
											</div>
										</div>
									)}
								</div>
							</div>

							<div className={"flex items-center py-[30px] px-5 lg:px-[30px] flex-wrap gap-[4px]"}>
								{metrics && (view === 1 || view === 2 || view === 3) && (
									<div className={"basis-1/2 md:basis-auto"}>
										<EmotionDropdown metrics={metrics} metric={metric} onClick={handleMetricClick.bind(this)} />
									</div>
								)}

								{demographics && (view === 0 || view === 2 || view === 3) && (
									<div className={"basis-1/2 md:basis-auto"}>
										<DemographyDropdown
											demographics={demographics}
											demography={demography}
											onClick={handleDemographyClick.bind(this)}
										/>
									</div>
								)}

								{devices && (view === 0 || view === 1 || view === 3) && (
									<div className={"basis-1/2 md:basis-auto"}>
										<DevicesDropdown devices={devices} device={device} onClick={handleDeviceClick.bind(this)} />
									</div>
								)}

								{publishers && (view === 0 || view === 1 || view === 2) && (
									<div className={"basis-1/2 md:basis-auto"}>
										<PublisherDropdown publishers={publishers} publisher={publisher} onClick={handlePublisherClick.bind(this)} />
									</div>
								)}

								{metrics && view === 0 && (
									<div className={"basis-full md:basis-auto"}>
										<MetricBadges
											metrics={metrics}
											onClick={handleMetricClick.bind(this)}
											metric={metric}
											onClear={clearFilter.bind(this)}
										/>
									</div>
								)}

								{demographics && view === 1 && (
									<div className={"basis-full md:basis-auto"}>
										<DemographyBadges
											demographics={demographics}
											demography={demography}
											onClick={handleDemographyClick.bind(this)}
											onClear={clearFilter.bind(this)}
										/>
									</div>
								)}

								{devices && view === 2 && (
									<div className={"basis-full md:basis-auto"}>
										<DeviceBadges
											devices={devices}
											onClick={handleDeviceClick.bind(this)}
											device={device}
											onClear={clearFilter.bind(this)}
										/>
									</div>
								)}

								{publishers && view === 3 && (
									<div className={"basis-full md:basis-auto"}>
										<PublisherBadges
											publishers={publishers}
											onClick={handlePublisherClick.bind(this)}
											publisher={publisher}
											onClear={clearFilter.bind(this)}
										/>
									</div>
								)}
							</div>

							{emotionsByDemography &&
								view === 1 &&
								emotionsByDemography.map(function (emotion, index) {
									return (
										emotion.active && (
											<div className='overflow-hidden' key={index}>
												<CampaignCardByDemography video={video} emotion={emotion} emotionIndex={index} />
											</div>
										)
									);
								})}

							{chartData.length !== 0 && (
								<div className='overflow-hidden'>
									<CampaignCard video={video} chartData={chartData} details={video} />
								</div>
							)}

							{learning && (
								<div className={"learning"}>
									<section className='flex items-center overflow-hidden bg-gray-200 max-sm:flex-wrap rounded-bl-xl rounded-br-xl'>
										<div className='flex items-center py-6 pr-8 lg:pr-16 justify-betweens basis-full sm:basis-10/12'>
											<div className='flex flex-wrap items-center basis-full'>
												<div className='flex px-8 mb-3 lg:mb-0 lg:justify-center basis-full min-w-[250px] lg:basis-4/12'>
													<p className='text-2xl'>Key Learnings</p>
												</div>
												<div className='px-8 lg:px-0 basis-full lg:basis-6/12'>
													<div dangerouslySetInnerHTML={{ __html: learning }} />
												</div>
											</div>
										</div>
									</section>
								</div>
							)}
						</div>
					</div>
				</div>
			</section>
		</main>
	);
}
