import * as React from "react";
import { useState, useRef, useEffect } from "react";
import ClearButton from "../elements/ClearButton";

export default function PublisherBadges({ campaignVideo, publishers, onClick, onClear, publisher }) {
	const [scrollPosition, setscrollPosition] = useState("left");
	const scrollContainerRef = useRef(null);
	const scrollElementRef = useRef(null);

	// container edge white fade effect on scroll
	useEffect(() => {
		const handleScroll = () => {
			const scrollContainer = scrollContainerRef.current;
			const scrollContainerWidth = Math.floor(scrollContainer.offsetWidth);
			const scrollValue = scrollContainer.scrollLeft;

			const scrollElement = scrollElementRef.current;
			const scrollWidth = Math.floor(scrollElement.scrollWidth);

			if (scrollValue === 0) {
				setscrollPosition("left");
			} else if (scrollValue >= scrollWidth - scrollContainerWidth) {
				setscrollPosition("right");
			} else {
				setscrollPosition("between");
			}
		};

		const scrollableElement = scrollContainerRef.current;
		scrollableElement.addEventListener("scroll", handleScroll);
		return () => {
			scrollableElement.removeEventListener("scroll", handleScroll);
		};
	}, [scrollPosition]);

	return (
		<div className='flex items-center space-x-5'>
			<div
				className={`relative overflow-hidden basis-auto
            ${scrollPosition === "right" ? "fade-left" : ""}
            ${scrollPosition === "left" ? "fade-right" : ""}
            ${scrollPosition === "between" ? "fade-left fade-right" : ""}
        `}
			>
				<div ref={scrollContainerRef} className='overflow-x-auto rounded-md prevent-scrollbar'>
					<div ref={scrollElementRef} className='pt-2 md:pt-0 flex flex-wrap lg:flex-nowrap space-x-2.5 min-w-full items-center'>
						{publishers &&
							publishers.map((item) => (
								<div className={"badgeElement"}>
									<button
										onClick={() => onClick(item, true)}
										className={`px-3 py-2.5 rounded-md transition-colors border text-[14px] xl:text-[16px] shadow-sm ${
											publisher.findIndex((obj) => obj === item) !== -1
												? "bg-[#F4F3FF] hover:bg-[#e8e6ff] active:bg-[#dad7fc] border-[#D5D2FF]"
												: "bg-white hover:bg-[#F7F7FB] active:bg-gray-200 border-[#D6DCDE]"
										}`}
										style={{
											backgroundColor: publisher.findIndex((obj) => obj === item) !== -1 && item.colors.background_color,
											color: publisher.findIndex((obj) => obj === item) !== -1 && item.colors.border_color,
										}}
									>
										<div className='flex items-center space-x-3'>
											<div className='leading-none whitespace-nowrap'>{item.name}</div>
											{item.tooltip && (
												<div className={"tooltip-trigger"}>
													<svg
														width='20px'
														height='20px'
														viewBox='0 0 20 20'
														version='1.1'
														xmlns='http://www.w3.org/2000/svg'
													>
														<g id='DASHBOARD' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
															<g
																id='DETAIL-infos'
																transform='translate(-1431.000000, -395.000000)'
																stroke='#000000'
																strokeWidth='1.5'
															>
																<g id='Group-2-Copy-2' transform='translate(1432.000000, 384.000000)'>
																	<g id='Info_duotone_line' transform='translate(0.000000, 12.000000)'>
																		<circle
																			id='Oval'
																			fillRule='nonzero'
																			transform='translate(9.000000, 9.000000) scale(-1, 1) translate(-9.000000, -9.000000) '
																			cx='9'
																			cy='9'
																			r='9'
																		/>
																		<line x1='9' y1='9' x2='9' y2='14.4' id='Path' strokeLinecap='round' />
																		<line x1='9' y1='5.5' x2='9' y2='4.6' id='Path' strokeLinecap='round' />
																	</g>
																</g>
															</g>
														</g>
													</svg>

													<div className={"tooltip"}>
														<div className={"tooltip-inner"}>{item.tooltip}</div>
													</div>
												</div>
											)}
										</div>
									</button>
								</div>
							))}
						<ClearButton onClick={() => onClear()} label='Clear' />
					</div>
				</div>
			</div>
		</div>
	);
}
