import * as React from "react";
import AccordionButton from "../elements/AccordionButton";

export default function CampaignVideoSumnary({ video }) {
	return (
		<section className='flex items-center mt-5 overflow-hidden bg-white max-sm:flex-wrap rounded-bl-xl rounded-br-xl'>
			<div
				id='origin-score'
				className='relative max-sm:py-10 z-0 flex items-center self-stretch justify-center space-x-2 overflow-hidden sm:max-w-[217px] basis-full sm:basis:auto'
			>
				<div className='text-[40px] leading-none text-black'>{video.score}</div>
				<div className='leading-none text-black uppercase text-md'>
					<div>Origin</div>
					<div>Score</div>
				</div>
			</div>
			<div className='flex items-center py-6 pr-8 lg:pr-16 justify-betweens basis-full sm:basis-10/12'>
				<div className='flex flex-wrap items-center basis-full'>
					<div className='flex px-8 mb-3 lg:mb-0 lg:justify-center basis-full min-w-[250px] lg:basis-4/12'>
						<p className='text-2xl'>Falling Short</p>
					</div>
					<div className='px-8 lg:px-0 basis-full lg:basis-6/12'>
						<p className='text-xs font-medium'>Based on Capture, Retain & Encode.</p>
						<p className='text-xs'>The creative shows signs of low performance that require your close attention.</p>
					</div>
				</div>
				<div className='flex justify-end basis-auto'>
					<AccordionButton />
				</div>
			</div>
		</section>
	);
}
