import * as React from "react";
import CampaignEmotionContainer from "../sections/CampaignEmotionContainer";
import CampaignChart from "./CampaignChart";
import CampaignChartSettings from "../sections/CampaignChartSettings";
import CampaignVideoSumnary from "./CampaignVideoSummary";

export default function CampaignCard({ video, chartData, details }) {
	return (
		<section className='border-b border-[#E6E6E6] mt-5'>
			<CampaignChart campaignVideo={video} chartData={chartData} details={details} />
			<CampaignChartSettings />
			{video.score && <CampaignVideoSumnary video={video} />}
		</section>
	);
}
